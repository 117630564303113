<template>
  <div class="w-full">
    <div class="mt-1 relative">
      <button
        @click.prevent="toggleDropdown"
        type="button"
        ref="buttonRef"
        aria-haspopup="listbox"
        aria-expanded="true"
        aria-labelledby="listbox-label"
        class="relative w-full bg-white border border-gray-300 rounded-sm shadow-sm pl-3 pr-10 py-3 text-left focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm hover:cursor-pointer"
        @keydown.down.prevent="onArrowDown"
        @keydown.up.prevent="onArrowUp"
        @keydown.enter.prevent="onEnter"
      >
        <span class="flex items-center">
          <span v-if="selectedVal" class="ml-3 block truncate">
            {{ selectedVal }}
          </span>
          <span v-else class="ml-3 block truncate text-gray-600">
            {{ placeholder }}
          </span>
        </span>
        <span
          class="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
        >
          <!-- Heroicon name: selector -->
          <svg
            class="h-5 w-5 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </span>
      </button>

      <transition
        enter-active-class=""
        enter-class="opacity-0"
        enter-to-class=""
        leave-active-class="transition ease-in duration-2000"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
        ref="itemsRef"
        v-show="showOptions"
      >
        <div class="absolute mt-1 w-full rounded-md bg-white shadow-lg">
          <ul
            tabindex="-1"
            role="listbox"
            aria-labelledby="listbox-label"
            aria-activedescendant="listbox-item-3"
            class="max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
            ref="optionElement"
          >
            <li
              role="text"
              v-for="({ value, label }, index) in options"
              class="cursor-default select-none relative py-2 pl-3 pr-9"
              :class="{
                'bg-indigo-600':
                  activeOptionIndex === index || value === selectedOption,
                'bg-opacity-10':
                  activeOptionIndex === index && value !== selectedOption,
                'text-white': value === selectedOption,
                'text-gray-900': value !== selectedOption,
                'hide-cursor': hideCursor
              }"
              @click="selectOption(value)"
              @mouseover.prevent="!hideCursor && (activeOptionIndex = index)"
              @mousemove="hideCursor = false"
              :key="value"
            >
              <div class="flex items-center">
                <span
                  :class="{ 'font-semibold': value === selectedOption }"
                  class="ml-3 block font-normal truncate"
                >
                  {{ label }}
                </span>
              </div>
              <span
                v-if="value === selectedOption"
                class="absolute inset-y-0 right-0 flex items-center pr-4"
              >
                <svg
                  class="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
            </li>
          </ul>
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  ref,
  onUnmounted,
  computed,
  watch,
  PropType,
  onUpdated
} from 'vue'
import { createPopper } from '@popperjs/core'

let popperInstance: any = null

interface Option {
  value: string
  label: string
}

export default defineComponent({
  props: {
    options: {
      type: Array as PropType<Option[]>,
      require: true,
      default: []
    },
    selectedOption: String,
    placeholder: String
  },
  setup(props, { emit }) {
    const showOptions = ref(false)
    const buttonRef = ref()
    const itemsRef = ref()
    const optionElement = ref()
    const activeOptionIndex = ref(-1)
    const hideCursor = ref(false)
    const scrollIntoViewOptions = {
      behavior: 'smooth',
      block: 'nearest'
    }
    const selectedVal = computed(() => {
      for (const option of props.options) {
        if (option.value === props.selectedOption) {
          return option.label
        }
      }
    })

    watch(showOptions, () => {
      props.options.forEach((option, index) => {
        if (option.value === props.selectedOption) {
          activeOptionIndex.value = index
        }
      })
    })

    onUpdated(() => {
      optionElement?.value?.children[activeOptionIndex.value]?.scrollIntoView({
        block: 'nearest'
      })
    })

    function toggleDropdown(e?: MouseEvent) {
      if (e) {
        e.stopPropagation()
        e.preventDefault()
      }
      if (showOptions.value) {
        showOptions.value = false
      } else {
        showOptions.value = true
        popperInstance = createPopper(buttonRef.value, itemsRef.value, {
          placement: 'bottom-start',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 4]
              }
            }
          ]
        })
      }
    }

    const onArrowDown = () => {
      hideCursor.value = true
      if (
        showOptions.value &&
        activeOptionIndex.value < props.options?.length - 1
      ) {
        activeOptionIndex.value += 1
        optionElement.value?.children[activeOptionIndex.value]?.scrollIntoView(
          scrollIntoViewOptions
        )
      }
    }

    const onArrowUp = () => {
      hideCursor.value = true
      if (showOptions.value && activeOptionIndex.value > 0) {
        activeOptionIndex.value -= 1
        optionElement.value?.children[activeOptionIndex.value]?.scrollIntoView(
          scrollIntoViewOptions
        )
      }
    }

    const onEnter = () => {
      showOptions.value = false
      emit('update-option', props.options[activeOptionIndex.value].value)
    }

    function selectOption(value: string) {
      showOptions.value = false
      emit('update-option', value)
    }

    function handler(event: MouseEvent) {
      const target = event.target as HTMLElement
      const active = document.activeElement
      if (buttonRef.value.contains(target)) return
      if (!itemsRef.value.contains(target)) showOptions.value = false
      if (active !== document.body && active?.contains(target)) return // Keep focus on newly clicked/focused element
    }

    onMounted(() => {
      window.addEventListener('click', handler)
    })

    onUnmounted(() => {
      if (popperInstance) {
        popperInstance.destroy()
        popperInstance = null
      }
      window.removeEventListener('click', handler)
    })

    return {
      showOptions,
      selectedVal,
      hideCursor,
      optionElement,
      activeOptionIndex,
      buttonRef,
      itemsRef,
      selectOption,
      toggleDropdown,
      onArrowDown,
      onArrowUp,
      onEnter
    }
  }
})
</script>

<style scoped>
.hide-cursor {
  cursor: none !important;
}
</style>
