<template>
  <div class="form-parent-container">
    <div class="form-child-container">
      <div class="md:text-lg lg:text-xl xl:text-2xl font-medium text-center">
        Tell us about your business
      </div>
      <div class="pt-4 sm:text-xs xl:text-sm text-gray-600 text-center">
        We’ll make this super duper easy for you, just tell us a bit more about
        your business
      </div>
      <div class="flex flex-col pt-20">
        <label id="listbox-label" class="label">
          What industry does your business serve?
        </label>
        <dropdown
          :options="industryOptions"
          :selectedOption="selectedIndustry"
          :placeholder="'Select industry'"
          @updateOption="updateIndustry"
        />
      </div>
      <error-message
        :isError="formErrors.industryError"
        message="This field is required."
      />
      <div class="pt-6">
        <label id="listbox-label" class="label">
          How many customers do you have?
        </label>
        <div class="flex flex-wrap">
          <SelectOption
            v-for="customerCount in customerCountOptions"
            :key="customerCount"
            @updateValue="updateCustomerCount"
            :isActive="customerCount === selectCustomerCount"
            :option="customerCount"
            class="mr-6 mb-2"
          />
        </div>
      </div>
      <error-message
        :isError="formErrors.customerCountError"
        message="This field is required."
      />
      <div class="pt-6">
        <label id="listbox-label" class="label">
          Do you have a website?
        </label>
        <div class="flex">
          <div class="flex items-center pr-4">
            <input
              id="have_website"
              name="website"
              type="radio"
              class="radioButton"
              v-model="haveWebsite"
              :value="true"
            />
            <label for="have_website" class="radioButton-label">
              Yes, I have
            </label>
          </div>
          <div class="flex items-center">
            <input
              id="dont_have_website"
              name="website"
              type="radio"
              class="radio-button"
              v-model="haveWebsite"
              :value="false"
            />
            <label for="dont_have_website" class="radioButton-label">
              No, I don't
            </label>
          </div>
        </div>
      </div>
      <div style="min-height: 4.5rem" class="pt-3">
        <input
          v-show="haveWebsite"
          v-model="website"
          type="text"
          name="street_address"
          class="input-text"
          placeholder="your website"
          @input="formErrors.websiteError = false"
        />
        <error-message
          :isError="formErrors.websiteError"
          message="This field is required."
        />
      </div>
      <div class="pt-8 flex justify-end">
        <button
          class="base-filled-button indigo-btn flex justify-center items-center"
          @click="nextStep"
        >
          <span class="mr-1">Next</span>
          <Icon icon="arrow-right" height="20" />
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import Dropdown from '@/components/UI/Dropdown.vue'
import ErrorMessage from '@/components/UI/ErrorMessage.vue'
import SelectOption from '@/components/UI/SelectOption.vue'
import { Icon, addIcon } from '@iconify/vue'
import arrowRight from '@iconify/icons-mdi/arrow-right'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

addIcon('arrow-right', arrowRight)

export default defineComponent({
  components: {
    Dropdown,
    SelectOption,
    Icon,
    ErrorMessage
  },
  setup() {
    const store = useStore()
    const router = useRouter()

    const selectedIndustry = ref(store.state.business.industryServed)
    const selectCustomerCount = ref(store.state.business.customerCount)
    const website = ref(store.state.business.websiteUrl)
    const haveWebsite = ref(store.state.business.haveWebsite)

    const formErrors = ref({
      industryError: false,
      customerCountError: false,
      websiteError: false
    })

    watch(haveWebsite, () => {
      formErrors.value.websiteError = false
      website.value = ''
    })

    const verifyErrors = () => {
      if (!selectedIndustry.value) formErrors.value.industryError = true
      if (!selectCustomerCount.value) formErrors.value.customerCountError = true
      if (haveWebsite.value && !website.value?.trim()) {
        website.value = website.value?.trim()
        formErrors.value.websiteError = true
      }
    }

    const industryOptions = [
      { value: 'Accounting / Financial', label: 'Accounting / Financial' },
      {
        value: 'Automobiles dealers / Repairs',
        label: 'Automobiles dealers / Repairs'
      },
      { value: 'Consulting / Agency', label: 'Consulting / Agency' },
      { value: 'Blogger / Author', label: 'Blogger / Author' },
      { value: 'E-commerce / Retail', label: 'E-commerce / Retail' },
      { value: 'Fitness / Nutrition', label: 'Fitness / Nutrition' },
      { value: 'Healthcare', label: 'Healthcare' },
      { value: 'Home service', label: 'Home service' },
      { value: 'Media / Publishing', label: 'Media / Publishing' },
      { value: 'Non-profit', label: 'Non-profit' },
      {
        value: 'Online training / Education',
        label: 'Online training / Education'
      },
      { value: 'Real estate', label: 'Real estate' },
      { value: 'Software', label: 'Software' },
      { value: 'Travel / Hospitality', label: 'Travel / Hospitality' },
      { value: 'Other', label: 'Other' }
    ]
    const customerCountOptions = [
      '1-3',
      '4-9',
      '10-49',
      '50+',
      'I don’t have any customers yet'
    ]

    const updateIndustry = (value: string) => {
      formErrors.value.industryError = false
      selectedIndustry.value = value
    }

    const updateCustomerCount = (value: string) => {
      formErrors.value.customerCountError = false
      selectCustomerCount.value = value
    }

    const nextStep = () => {
      verifyErrors()
      for (const error in formErrors.value) {
        if (formErrors.value[error]) return
      }
      store.commit('business/updateInfo', {
        industryServed: selectedIndustry.value,
        customerCount: selectCustomerCount.value,
        haveWebsite: haveWebsite.value,
        websiteUrl: website.value
      })

      // @ts-ignore
      store?.state?.iframe?.handshake?.then(parent => {
        parent.emit('ga-event', 'onb-bizprofile')
      })

      router.push({
        name: 'businessAddressPage'
      })
    }

    return {
      industryOptions,
      customerCountOptions,
      selectedIndustry,
      selectCustomerCount,
      haveWebsite,
      website,
      updateIndustry,
      updateCustomerCount,
      formErrors,
      nextStep
    }
  }
})
</script>

<style scoped type="scss"></style>
