
import { defineComponent, ref, watch } from 'vue'
import Dropdown from '@/components/UI/Dropdown.vue'
import ErrorMessage from '@/components/UI/ErrorMessage.vue'
import SelectOption from '@/components/UI/SelectOption.vue'
import { Icon, addIcon } from '@iconify/vue'
import arrowRight from '@iconify/icons-mdi/arrow-right'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

addIcon('arrow-right', arrowRight)

export default defineComponent({
  components: {
    Dropdown,
    SelectOption,
    Icon,
    ErrorMessage
  },
  setup() {
    const store = useStore()
    const router = useRouter()

    const selectedIndustry = ref(store.state.business.industryServed)
    const selectCustomerCount = ref(store.state.business.customerCount)
    const website = ref(store.state.business.websiteUrl)
    const haveWebsite = ref(store.state.business.haveWebsite)

    const formErrors = ref({
      industryError: false,
      customerCountError: false,
      websiteError: false
    })

    watch(haveWebsite, () => {
      formErrors.value.websiteError = false
      website.value = ''
    })

    const verifyErrors = () => {
      if (!selectedIndustry.value) formErrors.value.industryError = true
      if (!selectCustomerCount.value) formErrors.value.customerCountError = true
      if (haveWebsite.value && !website.value?.trim()) {
        website.value = website.value?.trim()
        formErrors.value.websiteError = true
      }
    }

    const industryOptions = [
      { value: 'Accounting / Financial', label: 'Accounting / Financial' },
      {
        value: 'Automobiles dealers / Repairs',
        label: 'Automobiles dealers / Repairs'
      },
      { value: 'Consulting / Agency', label: 'Consulting / Agency' },
      { value: 'Blogger / Author', label: 'Blogger / Author' },
      { value: 'E-commerce / Retail', label: 'E-commerce / Retail' },
      { value: 'Fitness / Nutrition', label: 'Fitness / Nutrition' },
      { value: 'Healthcare', label: 'Healthcare' },
      { value: 'Home service', label: 'Home service' },
      { value: 'Media / Publishing', label: 'Media / Publishing' },
      { value: 'Non-profit', label: 'Non-profit' },
      {
        value: 'Online training / Education',
        label: 'Online training / Education'
      },
      { value: 'Real estate', label: 'Real estate' },
      { value: 'Software', label: 'Software' },
      { value: 'Travel / Hospitality', label: 'Travel / Hospitality' },
      { value: 'Other', label: 'Other' }
    ]
    const customerCountOptions = [
      '1-3',
      '4-9',
      '10-49',
      '50+',
      'I don’t have any customers yet'
    ]

    const updateIndustry = (value: string) => {
      formErrors.value.industryError = false
      selectedIndustry.value = value
    }

    const updateCustomerCount = (value: string) => {
      formErrors.value.customerCountError = false
      selectCustomerCount.value = value
    }

    const nextStep = () => {
      verifyErrors()
      for (const error in formErrors.value) {
        if (formErrors.value[error]) return
      }
      store.commit('business/updateInfo', {
        industryServed: selectedIndustry.value,
        customerCount: selectCustomerCount.value,
        haveWebsite: haveWebsite.value,
        websiteUrl: website.value
      })

      // @ts-ignore
      store?.state?.iframe?.handshake?.then(parent => {
        parent.emit('ga-event', 'onb-bizprofile')
      })

      router.push({
        name: 'businessAddressPage'
      })
    }

    return {
      industryOptions,
      customerCountOptions,
      selectedIndustry,
      selectCustomerCount,
      haveWebsite,
      website,
      updateIndustry,
      updateCustomerCount,
      formErrors,
      nextStep
    }
  }
})
