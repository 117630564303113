<template>
  <div class="flex min-h-screen">
    <BusinessInfoSideBar />
    <BusinessInfoForm />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import BusinessInfoSideBar from '@/components/BusinessInfoSideBar.vue'
import BusinessInfoForm from '@/components/BusinessInfoForm.vue'

export default defineComponent({
  name: 'BusinessInfoPage',
  components: {
    BusinessInfoSideBar,
    BusinessInfoForm
  }
})
</script>

<style scoped></style>
