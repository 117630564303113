<template>
  <b v-show="isError" class="text-red-600 text-xs mt-1">
    {{ message }}
  </b>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    isError: {
      type: Boolean,
      required: true
    },
    message: {
      type: String,
      required: true
    }
  },
  setup() {
    return {}
  }
})
</script>

<style scoped></style>
