<template>
  <div
    type="div"
    class="bg-white py-3 px-16 border border-gray-300 rounded-sm shadow-sm text-sm font-medium text-gray-600 cursor-pointer hover:bg-gray-50"
    :class="
      isActive && [
        'outline-none',
        'ring-2',
        'ring-indigo-500',
        'border-indigo-500'
      ]
    "
    @click="$emit('update-value', option)"
  >
    {{ option }}
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SelectOption',
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    option: {
      type: String,
      required: true
    }
  }
})
</script>

<style scoped></style>
