
import { defineComponent } from 'vue'
import BusinessInfoSideBar from '@/components/BusinessInfoSideBar.vue'
import BusinessInfoForm from '@/components/BusinessInfoForm.vue'

export default defineComponent({
  name: 'BusinessInfoPage',
  components: {
    BusinessInfoSideBar,
    BusinessInfoForm
  }
})
