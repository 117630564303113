
import {
  defineComponent,
  onMounted,
  ref,
  onUnmounted,
  computed,
  watch,
  PropType,
  onUpdated
} from 'vue'
import { createPopper } from '@popperjs/core'

let popperInstance: any = null

interface Option {
  value: string
  label: string
}

export default defineComponent({
  props: {
    options: {
      type: Array as PropType<Option[]>,
      require: true,
      default: []
    },
    selectedOption: String,
    placeholder: String
  },
  setup(props, { emit }) {
    const showOptions = ref(false)
    const buttonRef = ref()
    const itemsRef = ref()
    const optionElement = ref()
    const activeOptionIndex = ref(-1)
    const hideCursor = ref(false)
    const scrollIntoViewOptions = {
      behavior: 'smooth',
      block: 'nearest'
    }
    const selectedVal = computed(() => {
      for (const option of props.options) {
        if (option.value === props.selectedOption) {
          return option.label
        }
      }
    })

    watch(showOptions, () => {
      props.options.forEach((option, index) => {
        if (option.value === props.selectedOption) {
          activeOptionIndex.value = index
        }
      })
    })

    onUpdated(() => {
      optionElement?.value?.children[activeOptionIndex.value]?.scrollIntoView({
        block: 'nearest'
      })
    })

    function toggleDropdown(e?: MouseEvent) {
      if (e) {
        e.stopPropagation()
        e.preventDefault()
      }
      if (showOptions.value) {
        showOptions.value = false
      } else {
        showOptions.value = true
        popperInstance = createPopper(buttonRef.value, itemsRef.value, {
          placement: 'bottom-start',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 4]
              }
            }
          ]
        })
      }
    }

    const onArrowDown = () => {
      hideCursor.value = true
      if (
        showOptions.value &&
        activeOptionIndex.value < props.options?.length - 1
      ) {
        activeOptionIndex.value += 1
        optionElement.value?.children[activeOptionIndex.value]?.scrollIntoView(
          scrollIntoViewOptions
        )
      }
    }

    const onArrowUp = () => {
      hideCursor.value = true
      if (showOptions.value && activeOptionIndex.value > 0) {
        activeOptionIndex.value -= 1
        optionElement.value?.children[activeOptionIndex.value]?.scrollIntoView(
          scrollIntoViewOptions
        )
      }
    }

    const onEnter = () => {
      showOptions.value = false
      emit('update-option', props.options[activeOptionIndex.value].value)
    }

    function selectOption(value: string) {
      showOptions.value = false
      emit('update-option', value)
    }

    function handler(event: MouseEvent) {
      const target = event.target as HTMLElement
      const active = document.activeElement
      if (buttonRef.value.contains(target)) return
      if (!itemsRef.value.contains(target)) showOptions.value = false
      if (active !== document.body && active?.contains(target)) return // Keep focus on newly clicked/focused element
    }

    onMounted(() => {
      window.addEventListener('click', handler)
    })

    onUnmounted(() => {
      if (popperInstance) {
        popperInstance.destroy()
        popperInstance = null
      }
      window.removeEventListener('click', handler)
    })

    return {
      showOptions,
      selectedVal,
      hideCursor,
      optionElement,
      activeOptionIndex,
      buttonRef,
      itemsRef,
      selectOption,
      toggleDropdown,
      onArrowDown,
      onArrowUp,
      onEnter
    }
  }
})
