
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SelectOption',
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    option: {
      type: String,
      required: true
    }
  }
})
